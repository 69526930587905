import i18n from '@/locales/index.js'
const { t } = i18n.global

// 编写好规则
export const reg_email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const rules = {
  username: [
    {
      required: true,
      message: t('register.form_rules.username.required'),
      trigger: ['blur', 'change']
    },
    {
      pattern: /^[a-zA-Z0-9._]{5,15}$/,
      message: t('register.form_rules.username.pattern'),
      trigger: 'blur'
    }
  ],
  email: [
    {
      required: true,
      message: t('register.form_rules.email.required'),
      trigger: ['blur', 'change']
    },
    {
      pattern: reg_email,
      message: t('register.form_rules.email.pattern'),
      trigger: ['blur']
    }
  ],
  captcha_text: [
    {
      required: true,
      message: t('register.form_rules.captcha_text.required'),
      trigger: ['blur', 'change']
    }
  ]
  // password: [
  //   {
  //     required: true,
  //     message: '请输入密码',
  //     trigger: ['blur', 'change']
  //   }
  // ]
}
