import request from '@/service/index'

export function getInviteInfo(inviteCode) {
  return request.get({
    url: `/portal/invite/${inviteCode}`
  })
}

export function inviteRegister(data) {
  return request.post({
    url: `/portal/invite/reg`,
    data
  })
}
