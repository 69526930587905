import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-47dff6e2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "invite"
};
const _hoisted_2 = {
  class: "container-hearder"
};
const _hoisted_3 = {
  key: 0,
  class: "container-box"
};
const _hoisted_4 = {
  key: 1,
  class: "container-box"
};
const _hoisted_5 = {
  class: "hint"
};
const _hoisted_6 = {
  class: "switch-lang"
};
const _hoisted_7 = {
  class: "bottom-copyright"
};
import FormLogo from '@/components/form-logo';
import SwitchLang from '@/components/switch-lang';
import BottomCopyright from '@/components/bottom-copyright';
import InvitePanel from './cpns/invite-panel.vue';
import { getInviteInfo } from '@/service/invite/invite.js';
import { useRoute } from 'vue-router';
import { ref } from 'vue';
export default {
  __name: 'invite',

  setup(__props) {
    const route = useRoute();
    const isInvite = ref(false);
    const inviteInfo = ref(null);
    getInviteInfo(route.params.invitecode).then(res => {
      if (res.result.org) {
        inviteInfo.value = res.result;
        isInvite.value = true;
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(FormLogo))]), isInvite.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(InvitePanel, {
        info: inviteInfo.value,
        inviteCode: _unref(route).params.invitecode
      }, null, 8, ["info", "inviteCode"])])) : _createCommentVNode("", true), !isInvite.value ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('invite.link_expired')), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_6, [_createVNode(_unref(SwitchLang))]), _createElementVNode("div", _hoisted_7, [_createVNode(_unref(BottomCopyright))])]);
    };
  }

};